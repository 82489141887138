/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import ProgressiveImage from 'react-progressive-graceful-image';
import queryString from 'query-string';

import Spinner from '../../../components/loader/spinner';

import { fetchResultDetails } from '../../../services/apis/business/result';

const lapsTableHeader = ['Distance', 'Time'];

function ResultDetails() {
  const location = useLocation();

  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const resultDetails = useCallback(async (raceID, event, bibNo, name) => {
    const response = await fetchResultDetails(raceID, event, bibNo, name);

    setLoading(false);
    setDetails(response);
  }, []);

  useEffect(() => {
    const { raceID, event, bibNo, name } = queryString.parse(location.search);

    resultDetails(raceID, event, bibNo, name).catch(error => {
      setLoading(false);

      throw error;
    });
  }, [resultDetails]);

  const rankFields = ['overallRank', 'categoryRank', 'genderRank'];

  const isTimeAvailable = details?.gunTime !== '00:00' || details?.netTime !== '00:00';

  const isRankingAvailable =
    details?.overallCount !== '-' || details?.categoryRank !== '-' || details?.genderRank !== '-';
  const hideAllRankFields = rankFields.every(value => details?.hideResultDetails.includes(value));

  const download = useCallback(() => {
    const { raceID, event, bibNo, name } = queryString.parse(location.search);

    window.open(
      `${process.env.REACT_APP_API_URL}/result/download?raceID=${raceID}&event=${event}&bibNo=${bibNo}&name=${name}`,
    );
  }, []);

  const convertToSeconds = useCallback(time => {
    const [hours, minutes, seconds] = time.split(':');

    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  }, []);

  const renderTimeBlock = (gunTime, netTime) => {
    if (!isTimeAvailable) {
      return null;
    }

    return (
      <div className="col-span-12 md:col-span-5 bg-white shadow-lg rounded-md border border-gray-300 py-4">
        <p className="pt-2 text-center font-bold text-base uppercase">Timing</p>
        <div className="flex">
          {gunTime !== '00:00' && !details.hideResultDetails.includes('gunTime') && (
            <div className="w-full p-3">
              <p className="text-sm text-center font-semibold uppercase">Gun Time</p>
              <p className="text-center text-xl my-1">
                <strong>{gunTime}</strong>
              </p>
            </div>
          )}
          {netTime !== '00:00' && !details.hideResultDetails.includes('netTime') && (
            <div className="w-full p-3">
              <p className="text-sm text-center font-semibold uppercase">Net Time</p>
              <p className="text-center text-xl my-1">
                <strong>{netTime}</strong>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-height flex items-center">
        <Spinner color="black" size="large" />
      </div>
    );
  }

  console.log(hideAllRankFields);

  return (
    <LightgalleryProvider>
      <Helmet>
        <title>
          Racetime India -{' '}
          {details ? `Results | ${details?.name} | ${details?.bibNo}` : 'Loading...'}{' '}
        </title>
      </Helmet>
      <div className="container max-w-4xl mx-auto px-4 pt-10 pb-10">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-4">
            <div className="flex flex-col items-center justify-center py-6 px-2 bg-white shadow-lg rounded-md border border-gray-300">
              {details.raceLogo && (
                <div className="mb-3">
                  <div className="shadow-lg">
                    <ProgressiveImage
                      delay={1000}
                      src={details.raceLogo}
                      placeholder={`${details.raceLogo}?tr=bl-10`}>
                      {src => <img src={src} alt={`${details.raceName}'s logo`} />}
                    </ProgressiveImage>
                  </div>
                </div>
              )}
              <p className="text-lg text-center">
                <strong>{details.raceName}</strong>
              </p>
              <p className="text-lg text-center">
                <strong>{details.raceDate}</strong>
              </p>
              <p className="text-base text-center mt-2">
                Event: <strong>{details.event}</strong>
              </p>
              {details.category && (
                <p className="text-base text-center mt-2">
                  Category: <strong>{details.category}</strong>
                </p>
              )}
            </div>
            {details.provisionalResult ? (
              <p className="text-center text-lg font-bold py-5">Provisional Result</p>
            ) : null}
          </div>
          <div className="col-span-12 md:col-span-8">
            <div className="bg-white shadow-lg rounded-md border border-gray-300 py-5">
              <p className="text-lg text-center">
                <strong>{details.name}</strong>
              </p>
              {details.gender && (
                <p className="text-base text-center mt-2">
                  GENDER: <strong>{details.gender === 'M' ? 'MALE' : 'FEMALE'}</strong>
                </p>
              )}
              <p className="text-base text-center mt-2">
                BIB: <strong>#{details.bibNo}</strong>
              </p>
            </div>
            {details.relay && (
              <div className="col-span-12 md:col-span-7 bg-white shadow-lg rounded-md border border-gray-300 py-4 mt-5">
                <p className="pt-2 text-center font-bold text-base uppercase">Team</p>
                <div className="flex">
                  {details.team ? (
                    <div className="w-full p-3">
                      <p className="text-sm text-center font-semibold uppercase">Name</p>
                      <p className="text-center text-xl my-1">
                        <strong>{details.team || '-'}</strong>
                      </p>
                    </div>
                  ) : null}
                  {details.teamLaps ? (
                    <div className="w-full p-3">
                      <p className="text-sm text-center font-semibold uppercase">Laps</p>
                      <p className="text-center text-xl my-1">
                        <strong>{details.teamLaps || '-'}</strong>
                      </p>
                    </div>
                  ) : null}
                  {details.teamTime ? (
                    <div className="w-full p-3">
                      <p className="text-sm text-center font-semibold uppercase">Time</p>
                      <p className="text-center text-xl my-1">
                        <strong>{details.teamTime || '-'}</strong>
                      </p>
                    </div>
                  ) : null}
                  {details.teamDistance ? (
                    <div className="w-full p-3">
                      <p className="text-sm text-center font-semibold uppercase">Distance (Kms)</p>
                      <p className="text-center text-xl my-1">
                        <strong>{details.teamDistance || '-'}</strong>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            <div className={`grid grid-cols-12 gap-3 ${isTimeAvailable && 'mt-5'}`}>
              {details.variant === 'DISTANCE_RACE' ? (
                renderTimeBlock(details.gunTime, details.netTime)
              ) : (
                <div className="col-span-12 md:col-span-5 bg-white shadow-lg rounded-md border border-gray-300 py-4">
                  <p className="pt-2 text-center font-bold text-base uppercase">Laps</p>
                  <div className="flex">
                    {details.lapsCount && (
                      <div className="w-full p-3">
                        <p className="text-sm text-center font-semibold uppercase">Count</p>
                        <p className="text-center text-xl my-1">
                          <strong>{details.lapsCount}</strong>
                        </p>
                      </div>
                    )}
                    {details.lapsDistance && (
                      <div className="w-full p-3">
                        <p className="text-sm text-center font-semibold uppercase">
                          Distance (Kms)
                        </p>
                        <p className="text-center text-xl my-1">
                          <strong>{Number(Number(details.distance).toFixed(2))}</strong>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {(isTimeAvailable || isRankingAvailable) && (
                <div className="col-span-12 md:col-span-7 bg-white shadow-lg rounded-md border border-gray-300 py-4">
                  <p className="pt-2 text-center font-bold text-base uppercase">Rank</p>
                  <div className="flex">
                    {details.overallRank && !details.hideResultDetails.includes('overallRank') ? (
                      <div className="w-full p-3">
                        <p className="text-sm text-center font-semibold uppercase">Overall</p>
                        <p className="text-center text-xl my-1">
                          <strong>{details.overallRank || '-'}</strong>
                        </p>
                        {details.overallCount ? (
                          <p className="text-center text-sm">
                            <strong>OF {details.overallCount}</strong>
                          </p>
                        ) : null}
                      </div>
                    ) : null}
                    {details.categoryRank && !details.hideResultDetails.includes('categoryRank') ? (
                      <div className="w-full p-3">
                        <p className="text-sm text-center font-semibold uppercase">
                          {details.category}
                        </p>
                        <p className="text-center text-xl my-1">
                          <strong>{details.categoryRank || '-'}</strong>
                        </p>
                        {details.categoryCount ? (
                          <p className="text-center text-sm">
                            <strong>OF {details.categoryCount}</strong>
                          </p>
                        ) : null}
                      </div>
                    ) : null}
                    {details.genderRank && !details.hideResultDetails.includes('genderRank') ? (
                      <div className="w-full p-3">
                        <p className="text-sm text-center font-semibold uppercase">
                          {details.gender === 'M' ? 'MALE' : 'FEMALE'}
                        </p>
                        <p className="text-center text-xl my-1">
                          <strong>{details.genderRank || '-'}</strong>
                        </p>
                        {details.genderCount ? (
                          <p className="text-center text-sm">
                            <strong>OF {details.genderCount}</strong>
                          </p>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            {details.laps.length > 0 && (
              <div className="bg-white shadow-lg rounded-md border border-gray-300 p-4 mt-5">
                <p className="pt-2 text-center font-bold text-base uppercase">Splits</p>
                <table className="table table-hover table-laps">
                  <thead className="text-center">
                    <tr className="border-0">
                      {lapsTableHeader.map((header, index) => (
                        <th key={index} scope="col">
                          <span className="text-center font-bold">{header}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {details.laps.map((item, index) => (
                      <tr key={index} className="transition-colors duration-500">
                        <td>
                          <span>{item.distance}</span>
                        </td>
                        <td>
                          <span>{convertToSeconds(item.time) > 0 ? item.time : ''}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {details.medal && (
              <div className="bg-white shadow-lg rounded-md border border-gray-300 p-4 mt-5" />
            )}
            <div className="bg-white shadow-lg rounded-md border border-gray-300 p-4 mt-5">
              <p className="pt-2 text-center font-bold text-base uppercase mb-3">Certificate</p>
              {details.certificate ? (
                <div className="flex justify-center pb-2">
                  <button type="button" className="button" onClick={() => download()}>
                    Download Certificate
                  </button>
                </div>
              ) : (
                <p className="text-center">
                  Certificates are currently unavailable or may not be accessible for all
                  participants at this time. Please check back later for updates. Thank you for your
                  patience
                </p>
              )}
            </div>
            {details.images.length ? (
              <div className="bg-white shadow-lg rounded-md border border-gray-300 p-4 mt-5">
                <p className="pt-2 text-center font-bold text-base uppercase mb-3">Photos</p>
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                  <Masonry gutter="15px">
                    {details.images.map((item, index) => (
                      <LightgalleryItem key={index} group="race-capture" src={item}>
                        <ProgressiveImage delay={1000} src={item} placeholder={`${item}?tr=bl-10`}>
                          {src => <img src={src} alt={`${details.bibNo}'s logo`} />}
                        </ProgressiveImage>
                      </LightgalleryItem>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </LightgalleryProvider>
  );
}

export default ResultDetails;
