import React from 'react';
import { useNavigate } from 'react-router-dom';

function home() {
  const navigate = useNavigate();

  return (
    <div className="bg-white">
      <div className="relative">
        <div
          className="home absolute w-full min-height bg-center bg-cover"
          style={{
            backgroundImage: 'url(/assets/images/home-background.jpg)',
          }}
        >
          <div className="w-full min-height absolute opacity-50 bg-black" style={{ zIndex: 1 }} />
        </div>
        <div className="min-height sticky flex items-center" style={{ zIndex: 2 }}>
          <div className="container max-w-2xl mx-auto">
            <div className="px-10 pt-10 pb-10 text-center">
              <h1 className="text-white font-semibold text-4xl sm:text-5xl">
                Registrations, Racing, Timing, Adventures
              </h1>
              <p className="mt-4 text-base sm:text-lg text-white">
                Your one stop location for all things related to running, cycling, triathlons,
                duathlons &amp; other sporting event. From race planning to award ceremony, we will
                take care of things for you.
              </p>
              <button
                type="button"
                className="button button--bold mt-4"
                onClick={() => navigate('/races')}
              >
                Race Calendar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-2xl mx-auto py-14 px-6 md:px-0 bg-white">
        <h1 className="text-black text-center font-semibold text-4xl sm:text-5xl mb-12">
          Our Own Events
        </h1>
        <div className="grid grid-cols-3 gap-10">
          <div className="col-span-3 sm:col-span-2">
            <p className="mt-4 text-lg leading-relaxed">
              WE ALSO CURATE OUR OWN EVENTS. The Bull Run at Nandi Hills happens in the month of
              August or September every year. A scenic mixed surface trail run, with a distance for
              kinds of runners from beginners to ultra marathoners. 8K/16K/25K/50K and a new
              distance this year 100K.
            </p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <img
              alt="Runner"
              className="max-w-full rounded-lg shadow-lg"
              src="/assets/images/home-runner.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default home;
