/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { XCircle, MagnifyingGlass } from 'phosphor-react';

import {
  fetchResultYearsList,
  fetchResultRacesList,
  fetchResultRacesSearch,
} from '../../../services/apis/business/race';

import InputField from '../../../components/fields/input';
import DropdownField from '../../../components/fields/dropdown';

import Spinner from '../../../components/loader/spinner';

import Pagination from '../../../components/layout/pagination';

function ResultRaces() {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [metadata, setMetadata] = useState(null);
  const [years, setYears] = useState([]);
  const [races, setRaces] = useState([]);
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);

  const { control, setValue, watch } = useForm({
    defaultValues: {
      search: '',
      year: null,
    },
  });

  const search = watch('search');
  const year = watch('year');

  const yearsList = useCallback(async () => {
    const response = await fetchResultYearsList();

    const list = response.map(item => ({
      id: item,
      name: item,
    }));

    setYears(list);
    setLoading(false);
  }, []);

  const racesList = useCallback(async (selectedYear, currentPage) => {
    setFetching(true);

    try {
      const response = await fetchResultRacesList(selectedYear, currentPage);

      const { races: list, totalDocs, totalPages, hasPrevPage, hasNextPage } = response;

      setRaces(list);
      setMetadata({
        totalDocs,
        totalPages,
        hasPrevPage,
        hasNextPage,
      });
      setSearched(false);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  }, []);

  const racesSearch = useCallback(async (selectedYear, searchTerm) => {
    try {
      const response = await fetchResultRacesSearch(selectedYear, searchTerm);

      const { races: list } = response;

      setRaces(list);
      setSearched(true);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  }, []);

  const handleSearchKeyDown = useCallback(
    code => {
      if (code === 13) {
        if (search) {
          racesSearch(year.name, search);
        } else {
          racesList(year.name, 1);
        }
      }
    },
    [search, year],
  );

  const handleSearchClick = useCallback(() => {
    if (search) {
      racesSearch(year.name, search);
    } else {
      racesList(year.name, 1);
    }
  }, [search, year]);

  const handleSearchCancel = useCallback(() => {
    setValue('search', '');
    racesList(year.name, 1);
  }, [year]);

  useEffect(() => {
    yearsList().catch(error => {
      setLoading(false);

      throw error;
    });
  }, [yearsList]);

  useEffect(() => {
    if (year) {
      setValue('search', '');

      if (page > 1) {
        setPage(1);
      } else {
        racesList(year.name, page);
      }
    }
  }, [year]);

  useEffect(() => {
    if (years.length) {
      setValue('year', years[0]);
    }
  }, [years]);

  useEffect(() => {
    if (year && page) {
      racesList(year.name, page);
    }
  }, [page]);

  useEffect(() => {
    scroll.scrollToTop({
      duration: 0,
      delay: 0,
      smooth: false,
    });
  }, [races]);

  return (
    <>
      <Helmet>
        <title>{year ? `Results | ${year?.name}` : 'Loading...'}</title>
      </Helmet>
      <div className="min-height">
        <div className="banner">
          <p>Results</p>
        </div>
        <div className="container max-w-4xl mx-auto py-4 px-6 md:px-8">
          <div className="flex flex-col-reverse sm:flex-row justify-evenly sm:justify-between">
            <div className="flex items-center justify-center sm:justify-start">
              {searched && (
                <button
                  type="button"
                  className="button button--text-only !px-0"
                  style={{ marginRight: '10px' }}
                  onClick={() => handleSearchCancel()}>
                  <XCircle size={20} weight="bold" />
                </button>
              )}
              <InputField
                name="search"
                control={control}
                placeholder="Search By Name"
                onKeyDown={event => handleSearchKeyDown(event.keyCode)}
              />
              <button type="button" className="button ml-2" onClick={() => handleSearchClick()}>
                <MagnifyingGlass size={18} weight="bold" />
              </button>
            </div>
            <DropdownField name="year" control={control} options={years} loading={loading} />
          </div>
          {fetching && (
            <div className="mt-8">
              <Spinner size="medium" />
            </div>
          )}
          {!fetching &&
            (races.length > 0 ? (
              <>
                <div className="grid grid-cols-12 gap-x-6 gap-y-10 mt-8 mb-6">
                  {races.map((race, index) => (
                    <div
                      key={index}
                      className="col-span-6 sm:col-span-3 xl:col-span-3 flex flex-col duration-1000 transition-all cursor-pointer rounded-xl"
                      role="presentation">
                      <div className="bg-white flex flex-col h-full shadow-lg rounded-xl relative border border-gray-300 mb-4">
                        <div className="h-full">
                          <LazyLoadImage
                            wrapperClassName="race-logo-wrapper"
                            className="race-logo-image"
                            alt={`${race.name}'s Logo`}
                            effect="blur"
                            src={race.logo}
                          />
                        </div>
                        <div className="text-center pb-4 px-3">
                          <h3 className="text-base font-semibold leading-normal">{race.name}</h3>
                        </div>
                      </div>
                      <div className="flex justify-around grow">
                        <button
                          type="button"
                          className="button"
                          onClick={() => navigate(`/results/list?race=${race.url}`)}>
                          Results
                        </button>
                        <button
                          type="button"
                          className="button"
                          onClick={() => navigate(`/results/photos?raceID=${race.id}`)}>
                          Photos
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                {!searched && (
                  <Pagination
                    currentPage={Number(page)}
                    step={2}
                    metadata={metadata}
                    onChange={value => setPage(value)}
                  />
                )}
              </>
            ) : (
              <div className="py-8">
                <p className="text-gray-700 text-center">No Races Found</p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default ResultRaces;
