/* eslint-disable no-bitwise */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { dataURLtoFile, cleanObject } from '../../../helper/utility';

import { notificationError } from '../../../redux/saga/notification/actions';

import FormBuilder from '../../../helper/formBuilder';

import RaceRegistrationUser from '../../../components/custom/fields/raceRegistrationUser';
import Spinner from '../../../components/loader/spinner';
import Button from '../../../components/button';

import CouponCode from '../../../components/custom/coupon';

import { fetchProfile } from '../../../services/apis/directory/user';
import { uploadDocument, createRegistration } from '../../../services/apis/business/registration';

import {
  fetchCalendarRaceDetails,
  fetchCalendarRaceForm,
} from '../../../services/apis/business/race';

import Consent from '../../../data/general/userConsent.json';

import { RADIO_GROUP_FIELD } from '../../../constants/form/types';

import VagamonSchema from '../../../helper/schema/vagamon';
import SarjapurSchema from '../../../helper/schema/sarjapur';
import XLRunathon from '../../../helper/schema/xl_runathon';
import RotaryRun from '../../../helper/schema/rotaryRun';
import Bahubali from '../../../helper/schema/bahubali';
import InputField from '../../../components/fields/input';

function RaceRegister() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { url } = useParams();

  const { user } = useSelector(state => state.session);

  const isMobile = useMediaQuery({ query: '(max-width: 425px)' });

  const [userSelection, setUserSelection] = useState(null);
  const [account, setAccount] = useState(null);
  const [emergency, setEmergency] = useState(null);
  const [details, setDetails] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [medical, setMedical] = useState(null);
  const [document, setDocument] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [totalDependency, setTotalDependency] = useState([]);
  const [total, setTotal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [registering, setRegistering] = useState(false);
  const [uploading, setUploading] = useState(false);

  const uploadDoc = url === 'vagamon-ultrail-2024';

  const uploadQualification = url === 'bahubali-ultra-2024';

  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      url === 'vagamon-ultrail-2024'
        ? VagamonSchema
        : url === 'xl-runathon-2024-bengaluru-edition'
        ? XLRunathon
        : url === 'rotary-june-run-2024'
        ? RotaryRun
        : url === 'bahubali-ultra-2024'
        ? Bahubali
        : SarjapurSchema,
    ),
  });

  const formData = useWatch({ control });

  const raceDetails = useCallback(async raceUrl => {
    const response = await fetchCalendarRaceDetails(raceUrl);

    setDetails(response);
  }, []);

  const raceForm = useCallback(async raceUrl => {
    const response = await fetchCalendarRaceForm(raceUrl);

    setLoading(false);
    setParticipant(response.participant);
    setMedical(response.medical);
  }, []);

  const profile = useCallback(async () => {
    const response = await fetchProfile();

    setAccount(response.account);
    setEmergency(response.emergency);
  }, []);

  const documentUpload = useCallback(async data => {
    setUploading(true);

    try {
      const response = await uploadDocument(data);

      const { filePath } = response;

      setDocument(filePath);

      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  }, []);

  const registerParticipant = useCallback(async data => {
    setRegistering(true);

    try {
      const response = await createRegistration(data);
      const { link } = response;

      setRegistering(false);

      if (link) {
        window.location.href = link;
      }
    } catch (error) {
      setRegistering(false);
    }
  }, []);

  const handleDocumentChange = useCallback(
    event => {
      const file = event.target.files[0];
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        const data = new FormData();
        data.append('raceID', details?.id);
        data.append('type', 'WAIVER');
        data.append('document', dataURLtoFile(fileReader.result, 'Document'));

        documentUpload(data);
      };

      if (file) {
        fileReader.readAsDataURL(file);
      }
    },
    [details],
  );

  const handleQualificationChange = useCallback(
    event => {
      const file = event.target.files[0];
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        const data = new FormData();
        data.append('raceID', details?.id);
        data.append('type', 'QUALIFICATION');
        data.append('document', dataURLtoFile(fileReader.result, 'Document'));

        documentUpload(data);
      };

      if (file) {
        fileReader.readAsDataURL(file);
      }
    },
    [details],
  );

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setLoading(true);

      profile().catch(error => {
        setLoading(false);

        throw error;
      });
    }
  }, [user, profile]);

  useEffect(() => {
    raceDetails(url).catch(error => {
      setLoading(false);

      throw error;
    });
  }, [raceDetails]);

  useEffect(() => {
    raceForm(url).catch(error => {
      setLoading(false);

      throw error;
    });
  }, [raceForm]);

  useEffect(() => {
    if (participant || medical) {
      setTotalDependency(
        participant.template.filter(item => item.shouldTotal).map(item => item.name),
      );

      reset({
        ...participant.defaultValue,
        ...medical?.defaultValue,
        waiver: false,
      });
    }
  }, [participant, medical]);

  useEffect(() => {
    if (Object.keys(formData).length) {
      setTotal(
        totalDependency.map(dependency => {
          const formField = participant.template.find(field => field.name === dependency);

          let id = formData[dependency]?.id;
          let name = formData[dependency]?.name;
          let fee = formData[dependency]?.metadata;

          if (formField.type === RADIO_GROUP_FIELD) {
            id = formField.id;
            name = formField.label;
            fee = formField.options.find(option => option.name === formData[dependency]).metadata;
          }

          if (fee === undefined || fee === 0) {
            return {};
          }

          return {
            id,
            name,
            fee,
          };
        }),
      );
    }
  }, [formData, totalDependency]);

  useEffect(() => {
    if (Object.keys(formData).length && participant) {
      let hidden = true;

      const { template } = participant;

      const field = template.find(item => item.name === 'timingCertificate');

      if (formData.event) {
        const { name } = formData.event;

        if (name !== '8K Trail Walk (₹400)') {
          hidden = false;
        }
      }

      if (field) {
        field.hidden = hidden;
      }
    }
  }, [formData, participant]);

  useEffect(() => {
    if (Object.keys(formData).length && participant) {
      const { template } = participant;

      const fields = template.filter(item => item.dependency.length > 0);

      fields.forEach(field => {
        const show = field.dependency.reduce((accumulator, item) => {
          const value = formData[item.field];

          accumulator = Boolean(value && item.value === value.name);

          return accumulator;
        }, false);

        field.hide = !show;
      });
    }
  }, [formData, participant]);

  useEffect(() => {
    if (userSelection && account && emergency) {
      const isSelf = userSelection === 'Self';

      if (account && emergency) {
        if (isSelf) {
          const { bloodGroup, shirtSize } = account;
          const { relationship } = emergency;

          setValue('firstName', account.firstName);
          setValue('lastName', account.lastName);
          setValue('email', account.email);
          setValue('phone', account.phone);
          setValue('dob', account.dob);
          setValue('gender', account.gender);
          setValue(
            'bloodGroup',
            bloodGroup
              ? {
                  id: bloodGroup.toLowerCase(),
                  name: bloodGroup,
                }
              : null,
          );
          setValue(
            'tShirt',
            shirtSize
              ? {
                  id: shirtSize.toLowerCase(),
                  name: shirtSize,
                }
              : null,
          );
          setValue('emergencyName', emergency.name || '');
          setValue('emergencyPhone', emergency.phone || '');
          setValue(
            'emergencyRelationship',
            relationship
              ? {
                  id: relationship.toLowerCase(),
                  name: relationship,
                }
              : null,
          );
        } else {
          setValue('firstName', '');
          setValue('lastName', '');
          setValue('email', '');
          setValue('phone', '');
          setValue('dob', '');
          setValue('gender', 'Male');
          setValue('bloodGroup', null);
          setValue('tShirt', null);
          setValue('emergencyName', '');
          setValue('emergencyPhone', '');
          setValue('emergencyRelationship', null);
        }
      }
    }
  }, [account, emergency, userSelection]);

  const calculateTotal = useCallback(
    () => total.reduce((accumulator, currentValue) => accumulator + (currentValue?.fee || 0), 0),
    [total],
  );

  const calculateDiscount = useCallback(() => {
    let offer = 0;

    if (coupon) {
      const { type, discount } = coupon;

      if (type === 'PERCENT') {
        offer = calculateTotal() * (discount / 100);
      }

      if (type === 'AMOUNT') {
        offer = discount;
      }
    }

    return offer;
  }, [total, coupon]);

  const addGST = useCallback(() => {
    const totalCost = total.reduce(
      (accumulator, currentValue) => accumulator + (currentValue?.fee || 0),
      0,
    );
    return ((totalCost - calculateDiscount()) * details.gstRate) / 100;
  }, [total, coupon, details]);

  const onSubmit = useCallback(
    data => {
      if (uploadDoc && !document) {
        dispatch(notificationError('Please upload the waiver form to proceed'));
        return;
      }

      if (uploadQualification && !document && !data.qualificationLink) {
        dispatch(notificationError('Please upload the race qualification file to proceed'));
        return;
      }

      setRegistering(true);

      const registration = {
        ...data,
        bloodGroup: data.bloodGroup.name,
        tShirt: data.tShirt?.name,
        event: data.event.name,
        emergencyRelationship: data.emergencyRelationship.name,
        employmentStatus: data.employmentStatus?.name,
      };

      const totalIDs = total
        .map(item => item.id)
        .filter(item => item !== null && item !== undefined);

      const userID = user?.id || null;

      const payload = {
        raceID: details.id,
        userID: userID && (userSelection === 'Self' ? userID : null),
        document,
        details: cleanObject(registration),
        ids: totalIDs,
        discount: coupon?.id || null,
      };

      registerParticipant(payload);
    },
    [document, details, user, userSelection, total, coupon],
  );

  if (loading) {
    return (
      <div className="min-height flex items-center">
        <Spinner color="black" size="large" />
      </div>
    );
  }

  const totalClean = total.filter(item => Object.keys(item).length);

  return (
    <>
      <Helmet>
        <title>{loading ? 'Loading...' : `${details.name} Registration`}</title>
      </Helmet>
      <div className="min-height">
        <div className="container max-w-4xl mx-auto px-4 py-6 md:px-8">
          <div className="flex items-baseline mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              strokeWidth={4}
              viewBox="0 0 24 24"
              aria-hidden="true"
              style={{ width: '20px', height: '20px' }}
              className="cursor-pointer"
              onClick={() => navigate(-1)}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
            <h1 className="text-2xl text-left font-bold ml-3">{details.name} Registration</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-5 row-gap-5">
              {Object.keys(user).length > 0 ? (
                <div className="col-span-12 sm:col-span-5">
                  <div className="bg-white shadow-lg rounded-md border border-gray-300 p-6">
                    <h1 className="mb-4 text-lg font-bold">User Selection</h1>
                    <RaceRegistrationUser onChange={value => setUserSelection(value)} />
                  </div>
                </div>
              ) : null}
              <div className="col-span-12">
                <div className="bg-white shadow-lg rounded-md border border-gray-300 px-6 pt-6 pb-8 sm:pb-6">
                  <h1 className="mb-6 text-lg font-bold">Participant Details</h1>
                  <div className="grid grid-cols-12 gap-5 row-gap-5">
                    {FormBuilder({
                      template: {
                        fields: participant.template,
                      },
                      control,
                    }).map(
                      ({ field }, index) =>
                        field && (
                          <div key={index} className="col-span-12 sm:col-span-6">
                            {field}
                          </div>
                        ),
                    )}
                  </div>
                </div>
                {medical && (
                  <div className="bg-white shadow-lg rounded-md border border-gray-300 px-6 pt-6 pb-8 sm:pb-6 mt-4">
                    <h1 className="mb-6 text-lg font-bold">Medical Questions</h1>
                    <div className="grid grid-cols-12 gap-5 row-gap-5">
                      {FormBuilder({
                        template: {
                          fields: medical.template,
                        },
                        control,
                      }).map(({ field }, index) => (
                        <div key={index} className="col-span-12 sm:col-span-6">
                          {field}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="bg-white shadow-lg rounded-md border border-gray-300 px-6 pt-6 pb-8 sm:pb-6 mt-4">
                  <h1 className="mb-4 text-lg font-bold">Documents</h1>
                  {uploadQualification && (
                    <>
                      <p className="text-sm mt-4">
                        Please upload the race qualification file{' '}
                        <span className="text-red-600">*</span>
                      </p>
                      <div className="flex items-center mt-3">
                        <div className="flex items-center">
                          <div>
                            <label type="button" htmlFor="document">
                              <span className="button cursor-pointer">
                                {document ? 'Uploaded' : uploading ? 'Uploading...' : 'Select File'}
                              </span>
                            </label>
                            <input
                              id="document"
                              accept="image/*,application/pdf"
                              hidden
                              type="file"
                              onChange={handleQualificationChange}
                              disabled={uploading}
                            />
                          </div>
                          {document && (
                            <div className="w-8 h-8 ml-3">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                className="check-svg"
                                viewBox="0 0 130.2 130.2">
                                <circle
                                  className="path circle"
                                  fill="none"
                                  stroke="#09d3ac"
                                  strokeWidth="6"
                                  strokeMiterlimit="10"
                                  cx="65.1"
                                  cy="65.1"
                                  r="62.1"
                                />
                                <polyline
                                  className="path check"
                                  fill="none"
                                  stroke="#09d3ac"
                                  strokeWidth="6"
                                  strokeLinecap="round"
                                  strokeMiterlimit="10"
                                  points="100.2,40.2 51.5,88.8 29.8,67.5 "
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <p className="mx-2">Or</p>
                        <InputField
                          name="qualificationLink"
                          control={control}
                          placeholder="Enter qualification file link"
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="bg-white shadow-lg rounded-md border border-gray-300 px-6 pt-6 pb-8 sm:pb-6 mt-4">
                  <h1 className="mb-4 text-lg font-bold">User Consent</h1>
                  <p className="text-base mb-3">
                    <a
                      href={details.waiver}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline">
                      Waiver Form
                    </a>
                  </p>
                  <div className="grid grid-cols-12 gap-5 row-gap-5">
                    {FormBuilder({
                      template: {
                        fields: Consent.template,
                      },
                      control,
                    }).map(({ field }, index) => (
                      <div key={index} className="col-span-12">
                        {field}
                      </div>
                    ))}
                  </div>
                  {uploadDoc && (
                    <>
                      <p className="text-sm mt-4">
                        Please Upload the first page of Waiver Form which must be attested by a
                        close relative or another runner participating in the race{' '}
                        <span className="text-red-600">*</span>
                      </p>
                      <div className="flex items-center mt-3">
                        <div>
                          <label type="button" htmlFor="document">
                            <span className="button cursor-pointer">
                              {document ? 'Uploaded' : uploading ? 'Uploading...' : 'Select File'}
                            </span>
                          </label>
                          <input
                            id="document"
                            accept="image/*,application/pdf"
                            hidden
                            type="file"
                            onChange={handleDocumentChange}
                            disabled={uploading}
                          />
                        </div>
                        {document && (
                          <div className="w-8 h-8 ml-3">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              className="check-svg"
                              viewBox="0 0 130.2 130.2">
                              <circle
                                className="path circle"
                                fill="none"
                                stroke="#09d3ac"
                                strokeWidth="6"
                                strokeMiterlimit="10"
                                cx="65.1"
                                cy="65.1"
                                r="62.1"
                              />
                              <polyline
                                className="path check"
                                fill="none"
                                stroke="#09d3ac"
                                strokeWidth="6"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                points="100.2,40.2 51.5,88.8 29.8,67.5 "
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-span-12 sm:col-span-7" />
              <div className="col-span-12 sm:col-span-5">
                <div className="bg-white shadow-lg rounded-md border border-gray-300 p-6">
                  <h1 className="mb-4 text-lg font-bold">Summary</h1>
                  {totalClean.length > 0 ? (
                    <>
                      <table className="summary w-full text-sm">
                        <thead>
                          <tr className="text-white">
                            <th>Item</th>
                            <th>Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {totalClean.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>₹{item.fee}</td>
                            </tr>
                          ))}
                          {coupon && (
                            <tr key="discount">
                              <td>Discount ({coupon.code})</td>
                              <td>-₹{calculateDiscount().toFixed(2)}</td>
                            </tr>
                          )}
                          {details.addGST && (
                            <tr key="gst">
                              <td>GST ({details.gstRate}%)</td>
                              <td>₹{addGST().toFixed(2)}</td>
                            </tr>
                          )}
                          {Boolean(details.processingFee) && (
                            <tr key="gst">
                              <td>Processing Fee</td>
                              <td>₹{details.processingFee}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <CouponCode raceID={details.id} coupon={coupon} onApply={setCoupon} />
                      <p className="font-medium text-base text-center mt-1 mb-3">
                        Total: <span>&#8377;</span>
                        {(
                          calculateTotal() -
                          calculateDiscount() +
                          (details.addGST ? addGST() : 0) +
                          details.processingFee
                        ).toFixed(2)}
                      </p>
                      <div className="flex justify-center">
                        <Button
                          label="Register"
                          fullWidth={isMobile}
                          loading={registering}
                          disabled={registering}
                        />
                      </div>
                    </>
                  ) : (
                    <p className="text-sm text-gray-600 text-center mb-3">No Items Added</p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default RaceRegister;
