/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import renderHTML from 'html-react-parser';
import ProgressiveImage from 'react-progressive-graceful-image';

import Spinner from '../../../components/loader/spinner';

import { fetchCalendarRaceDetails } from '../../../services/apis/business/race';

function RaceDetails() {
  const navigate = useNavigate();

  const { url } = useParams();

  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const raceDetails = useCallback(async raceUrl => {
    const response = await fetchCalendarRaceDetails(raceUrl);

    setLoading(false);
    setDetails(response);
  }, []);

  useEffect(() => {
    raceDetails(url).catch(error => {
      setLoading(false);

      throw error;
    });
  }, [raceDetails]);

  if (loading) {
    return (
      <div className="min-height flex items-center">
        <Spinner color="black" size="large" />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{loading ? 'Loading...' : details.name}</title>
      </Helmet>
      <div className="min-height">
        <div className="container max-w-4xl mx-auto px-4 pb-6 md:px-8">
          <section className="relative block h-52 sm:h-[470px]">
            <div
              className="w-full h-full bg-center bg-no-repeat bg-contain"
              style={{
                backgroundImage: `url(${details.cover})`,
              }}
            />
          </section>
          <section className="flex flex-col items-center sm:flex-row sm:items-center sm:justify-between py-3">
            <div className="flex flex-col items-center sm:flex-row mb-4 sm:mb-0">
              <ProgressiveImage
                delay={1000}
                src={details.logo}
                placeholder={`${details.logo}?tr=w-100,bl-10`}>
                {src => (
                  <img
                    alt={`${details.name}'s Logo`}
                    src={src}
                    className="h-auto"
                    style={{ maxWidth: '150px' }}
                  />
                )}
              </ProgressiveImage>
              <div className="sm:ml-4 mt-4 sm:mt-0">
                <h3 className="text-lg text-center sm:text-left sm:text-2xl font-bold leading-normal">
                  {details.name}
                </h3>
                <p className="text-sm text-center sm:text-left sm:text-base text-gray-500 font-semibold leading-normal mt-1">
                  {renderHTML(details.tag)}
                </p>
                <p className="text-sm text-center sm:text-left sm:text-base text-gray-500 font-semibold leading-normal mt-1">
                  Location: {renderHTML(details.location)}
                </p>
                <p className="text-sm text-center sm:text-left sm:text-base text-gray-500 font-semibold leading-normal mt-1">
                  {details.date}
                </p>
                <p className="text-sm text-center sm:text-left sm:text-base text-red-600 font-semibold leading-normal mt-1">
                  {details.isOpen
                    ? ''
                    : details.isPast
                    ? `Registrations have closed on: ${details.closeDate}`
                    : `Registrations will open on: ${details.openDate}`}
                </p>
              </div>
            </div>
            {details.isOpen && (
              <button
                type="button"
                className="button"
                onClick={() => navigate(`/race/${url}/register`)}>
                Register
              </button>
            )}
          </section>
          <section className="race-description">
            {details.closingMessage && (
              <h1 className="text-base text-center sm:text-xl text-green-600 font-semibold leading-normal mb-6 sm:mb-3">
                {renderHTML(details.closingMessage)}
              </h1>
            )}
            {renderHTML(details.description)}
            {details.isOpen && (
              <div className="flex flex-col items-center mt-5 w-full">
                <p className="text-sm text-red-600 text-center font-semibold mt-2">
                  Registrations will close on: {details.closeDate}
                </p>
                <button
                  type="button"
                  className="button"
                  onClick={() => navigate(`/race/${url}/register`)}>
                  Register
                </button>
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
}

export default RaceDetails;
