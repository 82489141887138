import http from '../../axios';

export const fetchCalendarYears = async () => http.get('/race/calendar/years');

export const fetchResultYearsList = async () => http.get('/race/result/years');

export const fetchCalendarRaces = async (year, page) =>
  http.get('/race/calendar/list', {
    params: {
      year,
      page,
    },
  });

export const fetchResultRacesList = async (year, page) =>
  http.get('/race/result/races', {
    params: {
      year,
      page,
    },
  });

export const fetchResultRacesSearch = async (year, search) =>
  http.get('/race/result/racesSearch', {
    params: {
      year,
      search,
    },
  });

export const fetchRacesSearch = async (year, search) =>
  http.get('/race/calendar/racesSearch', {
    params: {
      year,
      search,
    },
  });

export const fetchRaceDetails = async raceID =>
  http.get('/race/details', {
    params: {
      raceID,
    },
  });

export const fetchCalendarRaceDetails = async url =>
  http.get('/race/calendar/details', {
    params: {
      url,
    },
  });

export const fetchCalendarRaceForm = async url =>
  http.get('/race/calendar/form', {
    params: {
      url,
    },
  });

export const fetchCalendarRaceEvents = async url =>
  http.get('/race/calendar/events', {
    params: {
      url,
    },
  });
